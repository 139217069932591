import { randomIdeas } from '#app/utils.client/random-ideas.js'
import { cn } from '#app/utils/misc.ts'
import { useOptionalUser } from '#app/utils/user.js'
import { Link, useFetcher } from '@remix-run/react'
import { useState } from 'react'
import { Spacer } from '../spacer'
import { Button } from '../ui/button'
import { Icon } from '../ui/icon'
import { Label } from '../ui/label'
import { Textarea } from '../ui/textarea'

export function Top({ className }: { className?: string }) {
	const [randomIdeaIndex, setRandomIdeaIndex] = useState(0)
	const [whatToBuild, setWhatToBuild] = useState('')
	const user = useOptionalUser()

	const fetcher = useFetcher()
	const formData = new FormData()
	formData.append('whatToBuild', whatToBuild)

	const submitButton = (
		<Button
			className="w-full md:max-w-32 self-end"
			disabled={whatToBuild === ''}
			onClick={
				user !== undefined
					? () =>
							fetcher.submit(formData, {
								action: '/resources/wizard-landing-signed-up',
								method: 'post',
							})
					: undefined
			}
			size="lg"
			tabIndex={0}
		>
			<Icon className="text-lg text-white" name="wand-2">
				<span className="text-white">Start</span>
			</Icon>
		</Button>
	)

	return (
		<div className={cn('lg:-mx-12', className)}>
			<div className="">
				<div className="border-secondary-foreground/20 border-2 py-3 px-4 rounded-lg">
					<p className="text-lg font-semibold">
						BrainBuildAI helps you generate an app design in only a few minutes
					</p>
					<p className="mt-4 ">
						It walks you through the design process with an AI assistant, so you
						can focus on the creative part of building your app.
					</p>
				</div>
				<Spacer size="3xs" />
				<Label className="text-2xl text-primary" htmlFor="whatToBuild">
					What kind of app do you want to design today?
				</Label>
				<Textarea
					className="bg-background mt-2 text-xl min-h-[120px] border-2"
					id="whatToBuild"
					name="whatToBuild"
					onChange={(event) => {
						setWhatToBuild(event.target.value)
					}}
					placeholder="eg: a social network for cats"
					value={whatToBuild}
				/>
				<div className="flex justify-end mt-4">
					<Button
						className="mr-2"
						onClick={() => {
							setWhatToBuild(randomIdeas[randomIdeaIndex])
							setRandomIdeaIndex((randomIdeaIndex) => {
								return randomIdeaIndex >= randomIdeas.length - 1
									? 0
									: randomIdeaIndex + 1
							})
						}}
						size="lg"
						// tabIndex={2}
						variant="ghost"
					>
						<Icon name="dices">Random</Icon>
					</Button>
					{user !== undefined ? (
						submitButton
					) : (
						<Link to={`/signup?whatToBuild=${encodeURIComponent(whatToBuild)}`}>
							{submitButton}
						</Link>
					)}
				</div>
			</div>
		</div>
	)
}
