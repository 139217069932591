import { Container } from '../container.tsx'
import {
	Accordion,
	AccordionContent,
	AccordionItem,
	AccordionTrigger,
} from '../ui/accordion.tsx'

const faqs = [
	{
		answer:
			'Anything really! There is no real limitation with what kind of app it can help design, though it is a bit more focused on mobile rather than desktop currently',
		question: 'What types of apps can I create with BrainBuild?',
	},
	{
		answer:
			'There is a free tier which helps you get started. The pro tier lets you create more pages, projects, and export slides/PDFs ',
		question: 'Is it free? What is the pricing model?',
	},
	{
		answer:
			'No, BrainBuild does not code your app for you. What it does do is make it a lot easier for a developer to know exactly what to build.',
		question: 'Does it code my app for me?',
	},
	{
		answer:
			"Definitely not - we respect your privacy and confidentiality. (also we're too busy building BrainBuild to even have time to do that) ",
		question: 'Will you steal my idea?',
	},
	{
		answer:
			"Not really. It's more about getting the idea out of your head and into a format that a designer or developer can work with",
		question: 'Will the designs be polished?',
	},
] satisfies { answer: string; question: string }[]

export function Faqs() {
	return (
		<section
			aria-labelledby="faq-title"
			className="overflow-hidden bg-background py-8"
			id="faq"
		>
			<Container className="relative">
				<div className="">
					<h2
						className="font-display text-3xl tracking-tight text-foreground sm:text-4xl"
						id="faq-title"
					>
						Frequently Asked Questions
					</h2>
				</div>
				{/* <ul className="mx-auto mt-16 grid max-w-2xl grid-cols-1 gap-8 lg:max-w-none lg:grid-cols-3"> */}
				<Accordion collapsible type="single">
					{faqs.map(({ answer, question }) => (
						<AccordionItem key={question} value={question}>
							<AccordionTrigger>{question}</AccordionTrigger>
							<AccordionContent>{answer}</AccordionContent>
						</AccordionItem>
					))}
				</Accordion>
				{/* </ul> */}
			</Container>
		</section>
	)
}
